import React, { useContext } from 'react'
import './About.sass'
import { AuthContext } from '../../App'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Img from '../../ui/Img/Img'
import Button from '../../ui/Button/Button'
import avatar from './avatar.jpg'

function About() {
  return (
    <section id="about" className="About DefaultSection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="О компании" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <AboutContent
              description={
                <>
                  <p>
                    Управляющая компания «ТерраХоум» предлагает упростить
                    управление продажами и работу с персоналом в гостиницах,
                    хостелах, гостевых домах, домах отдыха, турбазах, коттеджах
                    и апартаментах!
                  </p>
                  <p>
                    Индивидуальный подход по управлению и ценовой политике к
                    каждому объекту размещения позволит сэкономить не только
                    Ваше время, но и средства, увеличив при этом прибыль от
                    Вашего бизнеса.
                  </p>
                  <p>
                    Управление продажами, подбор и обучение персонала,
                    оптимизация расходов или управление «под ключ» - Вы
                    выбираете вариант сотрудничества, а мы выводим Ваше средство
                    размещения на новый уровень!
                  </p>
                </>
              }
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export const AboutContent = ({ title = 'ТерраХоум', description }) => {
  const { setShowContactUs } = useContext(AuthContext)

  return (
    <div className="About-Container">
      <div className="About-Info">
        <h5 className="Title">{title}</h5>
        <div className="Description">{description}</div>
        <div className="Buttons">
          <Button
            title="Заказать обратный звонок"
            fill="accent2"
            theme="solid"
            onClick={() =>
              setShowContactUs({
                show: true,
                title: 'Заказать обратный звонок',
                emailSubject: 'Заказ обратного звонка',
              })
            }
          />
        </div>
      </div>
      <div className="Avatar">
        <Img src={avatar} />
      </div>
    </div>
  )
}

export default About
