import React from 'react'
import './Home.sass'
import Hero from '../../components/Hero/Hero'
import ContactUs from '../../components/ContactUs/ContactUs'
import Services from '../../components/Services/Services'
import Hotels from '../../components/Hotels/Hotels'
import About from '../../components/About/About'

function Home() {
  return (
    <div className="Home">
      <Hero />
      <Services />
      <Hotels />
      <About />
      <ContactUs />
    </div>
  )
}

export default Home
