import React from 'react'
// import Footer from '../../components/Footer/Footer'
import Nav from '../../components/Nav/Nav'
import './SitePage.sass'

function SitePage({ children }) {
  return (
    <>
      <Nav />
      {children}
      {/* <Footer /> */}
    </>
  )
}

export default SitePage
