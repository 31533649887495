import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyCLKMXk-eMlKgNqCoNwwkDo8kGu1c2FO9c',
  authDomain: 'isee-tarrahome.firebaseapp.com',
  projectId: 'isee-tarrahome',
  storageBucket: 'isee-tarrahome.appspot.com',
  messagingSenderId: '771785134861',
  appId: '1:771785134861:web:40643cf2b8b8d0eb2a5644',
}

firebase.initializeApp(firebaseConfig)

const firebaseSideEffect = firebase.initializeApp(
  firebaseConfig,
  'Side Effect instance'
)

const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const { storage } = firebase
const functions = firebase.app().functions('europe-west3')

export { db, storage, storageRef, firebase, firebaseSideEffect, functions }
