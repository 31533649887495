import React, { useContext, useEffect } from 'react'
import './HeroRent.sass'
import { withRouter } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import Button from '../../ui/Button/Button'
import herobg from './assets/herobg.svg'
import hero from './assets/rentimg.png'
import pattern from './assets/pattern.svg'
import { AuthContext } from '../../App'
import ArendaPopUp from '../Arenda/ArendaPopUp'

function Hero() {
  const { showRent, setShowRent } = useContext(AuthContext)

  return (
    <div
      id="home"
      className="Site-Hero"
      style={{
        backgroundImage: `url(${hero}), url(${herobg}), url(${pattern})`,
      }}
    >
      <div className="Offer-Rent">
        <h1 className="Offer-Title">
          Аренда квартир <span>в Кировске</span>
        </h1>
        <p className="Offer-Description">
          Комфортные условия проживания для туристов с оплатой за сутки
        </p>
        {showRent && (
          <ArendaPopUp rooms={showRent} close={() => setShowRent(false)} />
        )}
        <div className="Offer-Buttons">
          <>
            <NavHashLink
              to="#rent-catalog"
              smooth
              scroll={(el) => scrollWithOffset(el, -64)}
            >
              <Button
                title="Выбрать квартиру"
                fill="accent2"
                theme="solid"
                onClick={() => setShowRent(true)}
              />
            </NavHashLink>
          </>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Hero)
