import React, { useContext } from 'react'
import './Hero.sass'
import { withRouter } from 'react-router-dom'
import parse from 'html-react-parser'
import { NavHashLink } from 'react-router-hash-link'
import { ru } from '../../locale/ru'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import Button from '../../ui/Button/Button'
import herobg from './assets/herobg.svg'
import hero from './assets/hero.png'
import pattern from './assets/pattern.svg'
import { AuthContext } from '../../App'

function Hero() {
  const { setShowContactUs } = useContext(AuthContext)
  return (
    <div
      id="home"
      className="Site-Hero"
      style={{
        backgroundImage: `url(${hero}), url(${herobg}), url(${pattern})`,
      }}
    >
      <div className="Offer">
        <h1 className="Offer-Title">
          Комплексное управление
          <br />
          <span>средствами размещения</span>
        </h1>
        <p className="Offer-Description">
          Разработаем стратегию, внедрим и доведёт до результата!
        </p>
        <div className="Benefits">
          <BenefitCounter counter="9+" description="Объектов размещения" />
          <BenefitCounter counter="2+" description="Насёленных пункта" />
          <BenefitCounter counter="40+" description="Номерной фонд" />
        </div>
        <div className="Offer-Buttons">
          <>
            <NavHashLink
              to="/#services"
              smooth
              scroll={(el) => scrollWithOffset(el, -64)}
            >
              <Button
                title="Выбрать пакет услуг"
                fill="accent2"
                theme="solid"
              />
            </NavHashLink>
            <Button
              title="Заказать обратный звонок"
              theme="transparent-hug"
              onClick={() =>
                setShowContactUs({
                  show: true,
                  title: 'Обратный звонок',
                  emailSubject: 'Заказ обратного звонка с сайта',
                })
              }
            />
          </>
        </div>
      </div>
    </div>
  )
}

function BenefitCounter({ counter, description }) {
  return (
    <div className="BenefitCounter">
      <p className="Counter">{counter}</p>
      <p className="Description">{description}</p>
    </div>
  )
}

export default withRouter(Hero)
