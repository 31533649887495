import p1 from './assets/1.jpg'
import p2 from './assets/2.jpg'
import p3 from './assets/3.jpg'
import p4 from './assets/4.jpg'

export const packages = [
  {
    avatar: p4,
    title: 'Управление под ключ: доходы + расходы, работа с персоналом',
    // tag: {
    //   backgroundColor: '#459E8D',
    //   title: 'Выбор новичков',
    // },
    description:
      'Управление продажами, оптимизация и контроль над расходами, обучение и подбор персонала – все делаем мы. Вы получаете прибыль от успешного предприятия.',
    services: [
      'Полный аудит продаж (для работающих средств размещения)',
      'Полный аудит расходов (для работающих средств размещения)',
      'Проверка персонала на необходимость повышения квалификации и обучения или полной замены',
      'Разработка стратегии продаж',
      'Разработка тарифной политики',
      'Разработка тарифной сетки',
      'Ежедневный мониторинг цен',
      'Ежедневное управление уровнем цен и квот(для гостиниц и гостевых домов,без отдыха)',
      'Разработка и запуск акций и спецпредложений',
      'Ведение текущих каналов он-лайн продаж, подключение новых',
      'Ведение Яндекс и Гугл справочников',
      'Работа с отзывами гостей, обработка и увеличение лояльности',
      'Разработка шаблонов писем для рассылки, разработка маркетингового плана',
      'Разработка стратеги по оптимизации расходов',
      'Контроль над основными видами расходов',
      'Обучение персонала в специализированном обучающем центре',
      'Практические занятия непосредственно на месте размещения, закрепление полученного теоретического материала',
      'Тестирование после прохождения обучения',
      'Выдача необходимого методического материала',
      'Контроль над усвоением полученных знаний ( контрольные закупки, ежеквартальное тестирование)',
      'Разработка графиков выхода сотрудников на рабочие места, контроль их выхода',
      'Разработка стандартов обслуживания',
      'Разработка должностных инструкций для всего персонала',
      'Контроль над соблюдением должностных инструкций',
      'Контроль над соблюдением стандартов обслуживание',
      'Договорная работа с контрагентами',
      'Работа с надзорными и проверяющими органами, подготовка необходимой документации и нормативов',
      'Разработка шаблонов отчетности для отслеживания эффективности управлением средства размещения',
    ],
  },
  {
    avatar: p1,
    title: 'Управление доходом и он-лайн продажами',
    tag: {
      backgroundColor: '#459E8D',
      title: 'Выбор новичков',
    },
    description:
      'Берем на себя он-лайн продажи, ценообразование и доход средства размещения!',
    services: [
      'Полный аудит продаж (для работающих средств размещения)',
      'Разработка стратегии продаж',
      'Разработка тарифной политики',
      'Разработка тарифной сетки',
      'Ежедневный мониторинг цен',
      'Ежедневное управление уровнем цен и квот(для гостиниц и гостевых домов,без отдыха)',
      'Разработка и запуск акций и спецпредложений',
      'Ведение текущих каналов он-лайн продаж, подключение новых',
      'Ведение Яндекс и Гугл справочников',
      'Работа с отзывами гостей, обработка и увеличение лояльности',
      'Разработка шаблонов писем для рассылки, разработка маркетингового плана',
      'Разработка шаблонов отчетности для отслеживания эффективности стратегии продаж',
    ],
  },
  {
    avatar: p2,
    title: 'Управление доходом и он-лайн продажами + оптимизация расходов',
    // tag: {
    //   backgroundColor: '#459E8D',
    //   title: 'Выбор новичков',
    // },
    description:
      'Полный список услуг из пакета "Управление доходом и он-лайн продажами", а так же:',
    services: [
      'Полный аудит расходов (для работающих средств размещения)',
      'Разработка стратеги по оптимизации расходов',
      'Контроль над основными видами расходов',
      'Разработка шаблонов отчетности для отслеживания эффективности стратегии оптимизации расходов',
      'Полный аудит продаж (для работающих средств размещения)',
      'Разработка стратегии продаж',
      'Разработка тарифной политики',
      'Разработка тарифной сетки',
      'Ежедневный мониторинг цен',
      'Ежедневное управление уровнем цен и квот(для гостиниц и гостевых домов,без отдыха)',
      'Разработка и запуск акций и спецпредложений',
      'Ведение текущих каналов он-лайн продаж, подключение новых',
      'Ведение Яндекс и Гугл справочников',
      'Работа с отзывами гостей, обработка и увеличение лояльности',
      'Разработка шаблонов писем для рассылки, разработка маркетингового плана',
      'Разработка шаблонов отчетности для отслеживания эффективности стратегии продаж',
    ],
  },
  {
    avatar: p3,
    title: 'Обучение персонала',
    // tag: {
    //   backgroundColor: '#459E8D',
    //   title: 'Выбор новичков',
    // },
    description:
      'Обучим, подготовим, переквалифицируем персонал Вашего средства размещения.',
    services: [
      'Проверка персонала на необходимость повышения квалификации и обучения',
      'Обучение персонала в специализированном обучающем центре',
      'Практические занятия непосредственно на месте размещения, закрепление полученного теоретического материала',
      'Тестирование после прохождения обучения',
      'Выдача необходимого методического материала',
      'Разработка стандартов обслуживания',
      'Разработка должностных инструкций для всего персонала',
      'Контроль над усвоением полученных знаний ( контрольные закупки, ежеквартальное тестирование).',
    ],
  },
]
