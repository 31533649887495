import React from 'react'
import './SectionTitle.sass'

function SectionTitle({ title, theme = null }) {
  return (
    <h5 className={`SectionTitle ${theme ? 'SectionTitle_theme_white' : ''}`}>
      <span className="Title">
        {title}
        <span className="Decorator" />
      </span>
    </h5>
  )
}

export default SectionTitle
