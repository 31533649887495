import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import InfoLine from '../../ui/InfoLine/InfoLine'
import Img from '../../ui/Img/Img'
import Button from '../../ui/Button/Button'
import { hotels } from './hotelsArr'
import './Hotels.sass'
import pattern from './assets/pattern.svg'

function Hotels() {
  return (
    <section
      id="hotels"
      className="Hotels DefaultSection"
      style={{ backgroundImage: `url(${pattern})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Отели под нашим управлением" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="Hotels-Container">
              {hotels.map((h) => (
                <HotelTile {...h} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function HotelTile({
  avatar,
  avatarStyles,
  imageStyles,
  title,
  description,
  numbers,
  site,
  cottage,
  isGreen,
  moreButton,
}) {
  return (
    <div
      className={[
        'HotelTile',
        ...(isGreen ? ['HotelTile_theme_green'] : []),
      ].join(' ')}
    >
      <div className="Avatar" style={avatarStyles}>
        <img src={avatar} style={imageStyles} />
      </div>
      <div className="Content">
        <div className="Title">{title}</div>
        <InfoLine
          title={`Кол-во ${cottage ? 'квартир' : 'номеров'}`}
          content={numbers}
        />
        <p className="Description">{description}</p>
        {site && !moreButton && (
          <InfoLine
            title="Сайт"
            content={<a href={`http://${site}`}>{site}</a>}
            className="Site"
          />
        )}
        {moreButton && (
          <Button
            type="navlink"
            title="Выбрать квартиру"
            path={site}
            fill="accent2"
            theme="solid"
          />
        )}
      </div>
    </div>
  )
}

export default Hotels
