import React from 'react'
import Icon from '../../../ui/Icon/Icon'
import './PhoneChooser.sass'

function PhoneChooser() {
  return (
    <div className="PhoneChooser">
      <Icon name="mobile" weight="regular" className="ChooserIcon" />
      <a className="PhoneChooser-ActivePhone" href="tel: +7 (921) 158-80-80">
        +7 (921) 158-80-80
      </a>
    </div>
  )
}

export default PhoneChooser
