import React, { useEffect, useState } from 'react'
import PopUp from '../../ui/PopUp/PopUp'
import { Helmet } from 'react-helmet'

function ArendaPopUp({ rooms, close }) {
  return (
    <PopUp show title="Аренда квартир" close={close}>
      <div id="block-search">
        <div id="tl-search-form" class="tl-container">
          <noindex>
            <a
              href="https://www.travelline.ru/products/tl-hotel/"
              rel="nofollow noreferrer"
              target="_blank"
            >
              TravelLine
            </a>
          </noindex>
          <Helmet>
            <script src="/js/travelline.js"></script>
          </Helmet>
        </div>
      </div>

      {/* <div
        id="booking_iframe"
        style={{ paddingBottom: '32px', position: 'relative' }}
      >
        <div
          id="bn_iframe"
          style={{
            position: 'absolute',
            right: '0',
            marginTop: '12px',
            fontSize: '12px',
            lineHeight: '1em',
            opacity: '0.5',
            zIndex: '3',
            bottom: 0,
          }}
        >
          <div
            style={{
              color: '#1403FC !important',
              background: 'rgba(0, 0, 0, 0) !important;',
            }}
          >
            <a
              style={{
                color: '#808080 !important',
                background: '#ffffff !important;',
              }}
              href="http://bnovo.ru/"
              id="bnovo_link"
              target="_blank"
              rel="noreferrer"
            >
              Система управления отелем Bnovo ©
            </a>
          </div>
        </div>
      </div> */}
    </PopUp>
  )
}

export default ArendaPopUp
