const flats = [
  {
    id: 219117,
    title: '2-к. квартира, 56,3 м², 3/5 эт.',
    rooms: '2',
    floor: '3 из 5',
    space: '56.3 м²',
    avatar: 'kirova44/avatar.jpg',
    slider: [
      'kirova44/avatar.jpg',
      'kirova44/2.jpg',
      'kirova44/3.jpg',
      'kirova44/4.jpg',
      'kirova44/5.jpg',
      'kirova44/6.jpg',
      'kirova44/7.jpg',
    ],
    tech:
      'Wi-Fi, телевидение, холодильник, плита, микроволновка, стиральная машина, посудомоечная машина, водонагреватель, телевизор, фен, утюг',
    other: 'постельное белье, полотенца, средства гигиены',
    zalog: '5000₽',
    price: 'от <span>5000₽</span> за сутки',
    address: 'Мурманская область, Кировск, ул. Кирова, 44',
    maxTourists: 6,
    map: `<iframe src="https://yandex.ru/map-widget/v1/-/CCUBv6hmXB" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe>`,
  },
  {
    id: 187858,
    title: '3-к. квартира, 78,1 м², 4/4 эт.',
    rooms: '3',
    floor: '4 из 4',
    space: '78.1 м²',
    avatar: 'kirova4/avatar.jpg',
    slider: [
      'kirova4/avatar.jpg',
      'kirova4/1.jpg',
      'kirova4/2.jpg',
      'kirova4/3.jpg',
      'kirova4/4.jpg',
      'kirova4/5.jpg',
      'kirova4/6.jpg',
    ],
    tech:
      'Wi-Fi, телевидение, холодильник, плита, микроволновка, стиральная машина, посудомоечная машина, водонагреватель, телевизор, фен, утюг',
    other: 'постельное белье, полотенца, средства гигиены',
    zalog: '5000₽',
    price: 'от <span>5200₽</span> за сутки',
    address: 'Мурманская область, Кировск, ул. Кирова, 4',
    maxTourists: 6,
    map: `<iframe src="https://yandex.ru/map-widget/v1/-/CCUBv6tUWB" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe>`,
  },
  {
    id: 187859,
    title: '2-к. квартира, 61,3 м², 5/5 эт.',
    rooms: '2',
    floor: '5 из 5',
    space: '61.3 м²',
    avatar: 'kirova46/avatar.jpg',
    slider: [
      'kirova46/avatar.jpg',
      'kirova46/1.jpg',
      'kirova46/2.jpg',
      'kirova46/3.jpg',
      'kirova46/4.jpg',
      'kirova46/5.jpg',
      'kirova46/6.jpg',
      'kirova46/7.jpg',
      'kirova46/8.jpg',
      'kirova46/9.jpg',
      'kirova46/10.jpg',
      'kirova46/11.jpg',
      'kirova46/12.jpg',
      'kirova46/13.jpg',
      'kirova46/14.jpg',
      'kirova46/15.jpg',
    ],
    tech:
      'Wi-Fi, телевидение, холодильник, плита, микроволновка, стиральная машина, посудомоечная машина, водонагреватель, телевизор, фен, утюг',
    other: 'постельное белье, полотенца, средства гигиены',
    zalog: '5000₽',
    price: 'от <span>5000₽</span> за сутки',
    address: 'Мурманская область, Кировск, ул. Кирова, 46',
    maxTourists: 6,
    map: `<iframe src="https://yandex.ru/map-widget/v1/-/CCUBv6tDpB" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe>`,
  },
  {
    id: 187856,
    title: '1-к. квартира, 31,4 м², 4/5 эт.',
    rooms: '1',
    floor: '4 из 5',
    space: '31.4 м²',
    avatar: 'kirova45/avatar.jpg',
    slider: [
      'kirova45/avatar.jpg',
      'kirova45/1.jpg',
      'kirova45/2.jpg',
      'kirova45/3.jpg',
      'kirova45/4.jpg',
      'kirova45/5.jpg',
      'kirova45/6.jpg',
    ],
    tech:
      'Wi-Fi, телевидение, холодильник, плита, микроволновка, стиральная машина, посудомоечная машина, водонагреватель, телевизор, фен, утюг',
    other: 'постельное белье, полотенца, средства гигиены',
    zalog: '3000₽',
    price: 'от <span>2600₽</span> за сутки',
    address: 'Мурманская область, Кировск, ул. Кирова, 45',
    maxTourists: 3,
    map: `<iframe src="https://yandex.ru/map-widget/v1/-/CCUBv6xatD" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe>`,
  },
  {
    id: 187857,
    title: '1-к. квартира, 30,5 м², 3/5 эт.',
    rooms: '1',
    floor: '3 из 5',
    space: '30.5 м²',
    avatar: 'kirova39/avatar.jpg',
    slider: [
      'kirova39/2.jpg',
      'kirova39/3.jpg',
      'kirova39/4.jpg',
      'kirova39/5.jpg',
      'kirova39/6.jpg',
      'kirova39/7.jpg',
      'kirova39/8.jpg',
      'kirova39/9.jpg',
      'kirova39/10.jpg',
      'kirova39/11.jpg',
    ],
    tech:
      'Wi-Fi, телевидение, холодильник, плита, микроволновка, стиральная машина, посудомоечная машина, водонагреватель, телевизор, фен, утюг',
    other: 'постельное белье, полотенца, средства гигиены',
    zalog: '3000₽',
    price: 'от <span>2600₽</span> за сутки',
    address: 'Мурманская область, Кировск, ул. Кирова, 39',
    maxTourists: 3,
    map: `<iframe src="https://yandex.ru/map-widget/v1/-/CCUBv6xGDD" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe>`,
  },
  {
    id: 187860,
    title: '1-к. квартира, 29,8 м², 5/5 эт.',
    rooms: '1',
    floor: '5 из 5',
    space: '29,8 м²',
    avatar: 'kirova50/avatar.jpg',
    slider: [
      'kirova50/avatar.jpg',
      'kirova50/1.jpg',
      'kirova50/2.jpg',
      'kirova50/3.jpg',
      'kirova50/4.jpg',
      'kirova50/5.jpg',
    ],
    tech:
      'Wi-Fi, телевидение, холодильник, плита, микроволновка, стиральная машина, посудомоечная машина, водонагреватель, телевизор, фен, утюг',
    other: 'постельное белье, полотенца, средства гигиены',
    zalog: '3000₽',
    price: 'от <span>2600₽</span> за сутки',
    address: 'Мурманская область, Кировск, ул. Кирова, 50',
    maxTourists: 3,
    map: `<iframe src="https://yandex.ru/map-widget/v1/-/CCUBvCAaCA" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe>`,
  },
]

export default flats
