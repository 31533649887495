import React, { useContext } from 'react'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import PopUp from '../../../ui/PopUp/PopUp'
import './FlatPopUp.sass'
import Carousel from '../../../ui/Carousel/Carousel'
import parse from 'html-react-parser'
import Button from '../../../ui/Button/Button'
import { AuthContext } from '../../../App'

function FlatPopUp({
  id,
  title,
  rooms,
  space,
  floor,
  tech,
  other,
  maxTourists,
  address,
  onClose,
  slider,
  map,
}) {
  const { setShowRent } = useContext(AuthContext)
  return (
    <PopUp title={title} show className="FlatPopUp" close={onClose}>
      <div className="FlatPopUp-Container">
        <div className="Slider">
          <Carousel
            element="TourCarousel"
            options={{
              type: 'carousel',
              perView: 1,
              autoplay: 5000,
              useDots: true,
              useArrows: true,
            }}
          >
            {slider.map((src) => (
              <a
                href={`https://storage.googleapis.com/isee-tarrahome.appspot.com/${src}`}
                data-fancybox
              >
                <img
                  key={src}
                  src={`https://storage.googleapis.com/isee-tarrahome.appspot.com/${src}`}
                />
              </a>
            ))}
          </Carousel>
          <div className="Address-Container">
            <div className="Title">Расположение</div>
            <p>{address}</p>
            <p className="Map">{parse(map)}</p>
          </div>
        </div>
        <div className="InfoBlock">
          <div className="Title">О квартире</div>
          <div className="InfoBlock-Container">
            <InfoLine title="Кол-во комнат" content={rooms} />
            <InfoLine title="Общая площадь" content={space} />
            <InfoLine title="Этаж" content={floor} />
            <InfoLine title="Техника" content={tech} />
            <InfoLine title="Комфорт" content={other} />
          </div>
          <div className="Title">Описание</div>
          <div className="InfoBlock-Container">
            <p>
              В распоряжении наших гостей все необходимое, чтобы почувствовать
              себя как дома. Стиральная машинка, гладильная доска, утюг,
              сушилка, фен, телевизор, микроволновая печь, холодильник, чайник,
              посуда, постельное белье и полотенца. А так же хорошая аура и
              превосходный вид на горы. В шаговой доступности магазины Пятерочка
              и Магнит, рядом мини-отель "Горная Долина", где вы сможете плотно
              позавтракать за приемлемые деньги. Ждем Вас!
            </p>
          </div>
          <div className="Title">Правила</div>
          <div className="InfoBlock-Container">
            <InfoLine title="Кол-во жильцов" content={maxTourists} />
            <InfoLine title="Можно с детьми" content={'да'} />
            <InfoLine title="Можно с животными" content={'нет'} />
            <InfoLine title="Можно курить" content={'нет'} />
            <InfoLine title="Разрешены вечеринки" content={'нет'} />
            <InfoLine title="Есть отчетные документы" content={'да'} />
          </div>
          <Button
            title="Арендовать квартиру"
            fill="accent2"
            theme="solid"
            onClick={() => {
              setShowRent(id)
              onClose()
            }}
          />
        </div>
      </div>
    </PopUp>
  )
}

export default FlatPopUp
