import React, { useState } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import flats from './flats'
import parse from 'html-react-parser'
import './RentCatalog.sass'
import FlatPopUp from './FlatPopUp/FlatPopUp'
import { find } from 'lodash'

function RentCatalog() {
  const [activeFlat, setActiveFlat] = useState(false)
  console.log(
    '🚀 ~ file: RentCatalog.jsx ~ line 11 ~ RentCatalog ~ activeFlat',
    activeFlat
  )

  const onFlatClicked = (id) => {
    setActiveFlat(find(flats, ['id', id]))
  }

  return (
    <section id="rent-catalog" className="RentCatalog DefaultSection">
      {activeFlat && (
        <FlatPopUp {...activeFlat} onClose={() => setActiveFlat(false)} />
      )}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Каталог квартир" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="RentCatalog-Container">
              {flats.map((f) => (
                <FlatTile
                  key={f.id}
                  onClick={() => onFlatClicked(f.id)}
                  {...f}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function FlatTile({ avatar, title, price, address, onClick }) {
  return (
    <div className="FlatTile" onClick={onClick}>
      <img
        src={`https://storage.googleapis.com/isee-tarrahome.appspot.com/${avatar}`}
        alt=""
      />
      <div className="FlatTile-Info">
        <p className="Title">{title}</p>
        <p className="Price">{parse(price)}</p>
        <p className="Address">{address}</p>
      </div>
    </div>
  )
}

export default RentCatalog
