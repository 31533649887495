import React, { useContext, useState } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Button from '../../ui/Button/Button'
import './Services.sass'
import { packages } from './packages'
import Icon from '../../ui/Icon/Icon'
import Img from '../../ui/Img/Img'
import { slice, take } from 'lodash'
import sbg from './assets/sbg.svg'
import { AuthContext } from '../../App'

function Services() {
  return (
    <section
      id="services"
      className="Services DefaultSection"
      style={{ backgroundImage: `url(${sbg})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Управление отелями" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="Services-Container">
              {packages.map((p) => (
                <ServiceTile key={p.title} {...p} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function ServiceTile({ avatar, tag, title, description, services }) {
  const [showAll, setShowAll] = useState(false)
  const { setShowContactUs } = useContext(AuthContext)

  return (
    <div className="ServiceTile">
      <div className="Avatar">
        {tag && (
          <span
            className="Tag"
            style={{ backgroundColor: tag.backgroundColor }}
          >
            {tag.title}
          </span>
        )}
        <Img src={avatar} />
      </div>
      <div className="Body">
        <h4 className="Title">{title}</h4>
        <p className="Description">{description}</p>
        <div className="Content">
          <p className="Content-Section">Что входит?</p>
          <p className="Content-Info">
            {take(services, 4).map((s) => (
              <DescriptionLine icon="check" title={s} />
            ))}
          </p>
          <p
            className="HiddenService"
            style={{ display: showAll ? 'grid' : 'none' }}
          >
            {slice(services, 3, services.length).map((s) => (
              <DescriptionLine icon="check" title={s} />
            ))}
          </p>
          <p className="ShowAllButton" onClick={() => setShowAll(!showAll)}>
            {showAll ? 'Свернуть' : 'Просмотреть'} весь список{' '}
            <Icon
              name={showAll ? 'angle-up' : 'angle-right'}
              weight="regular"
            />
          </p>
          <div className="Buttons">
            <Button
              title="Узнать подробнее о пакете"
              fill="accent2"
              theme="solid"
              onClick={() =>
                setShowContactUs({
                  show: true,
                  title: 'Узнать подробнее о пакете',
                  emailSubject: `Заказ КП о пакете услуг "${title}"`,
                  additionalContent: `
                    Пакет услуг: ${title}
                  `,
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function DescriptionLine({ icon, title }) {
  return (
    <div style={{ display: 'flex' }}>
      <Icon
        name={icon}
        style={{ marginTop: '4px', color: '#FD7767', fontSize: '18px' }}
        weight="solid"
      />
      <p style={{ marginLeft: '16px' }}>{title}</p>
    </div>
  )
}

export default Services
