import React, { useEffect, useRef } from 'react'
import './Nav.sass'
import Hamburger from 'react-hamburger-menu'
import Media from 'react-media'
import { withRouter } from 'react-router-dom'
import Logo from '../../ui/Logo/Logo'
import SiteNavLink from './SiteNavLink'
import { usePopUp } from '../../hooks/usePopUp'
import useScrollPosition from '../../hooks/useScrollPosition'
import PhoneChooser from './PhoneChooser/PhoneChooser'

function Nav({ user, ...router }) {
  const hamRef = useRef()
  const navRef = useRef()
  const [menuIsOpen, setMenuIsOpen] = usePopUp(hamRef, navRef)
  const pos = useScrollPosition()

  useEffect(() => {
    setMenuIsOpen(false)
  }, [router.location.pathname, router.location.hash])

  return (
    <nav
      id="nav"
      className={[
        'Site-Nav',
        ...(pos > 0 ? ['Site-Nav_theme_shadow'] : []),
      ].join(' ')}
    >
      <Media
        query="(max-width: 1024px)"
        render={() => (
          <span ref={hamRef}>
            <Hamburger
              isOpen={menuIsOpen}
              width={24}
              height={16}
              strokeWidth={2}
              className="Site-Hamburger-Container"
              color="#459E8D"
              borderRadius={2}
              animationDuration={0.2}
            />
          </span>
        )}
      />
      <Logo theme="accent" navLink path="/#home" />
      <div
        ref={navRef}
        className="Site-Nav-Menu"
        style={menuIsOpen ? { display: 'grid' } : {}}
      >
        <SiteNavLink title="Управление отелями" path="/#services" />
        <SiteNavLink title="Наши отели" path="/#hotels" />
        <SiteNavLink
          title="Аренда квартир"
          path="/arenda-kvartiry-v-kirovske"
        />
        <SiteNavLink title="О компании" path="/#about" />
        <SiteNavLink title="Контакты" path="/#contacts" />
        <PhoneChooser />
      </div>
      <div className="MobileContacts">
        <PhoneChooser />
      </div>
    </nav>
  )
}

// const mapStateToProps = (state) => ({ user: state.auth.user })

export default withRouter(Nav)
