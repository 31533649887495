import React from 'react'
import About from '../About/About'
import ContactUs from '../ContactUs/ContactUs'
import HeroRent from '../HeroRent/HeroRent'
import RentCatalog from '../RentCatalog/RentCatalog'

function Arenda() {
  return (
    <div className="Home">
      <HeroRent />
      <RentCatalog />
      <About />
      <ContactUs />
    </div>
  )
}

export default Arenda
