import h1 from './assets/1.jpg'
import h2 from './assets/2.jpg'
import h3 from './assets/3.svg'
import h4 from './assets/4.jpg'
import h5 from './assets/5.png'

export const hotels = [
  {
    avatar: h1,
    title: 'Мини-отель "Горная долина"',
    description:
      'Уютный отель, расположенный у подножья горы и одноименного горнолыжного курорта Кукисвумчорр , номерной фонд 15 номеров от одноместного до номера-студио',
    numbers: 15,
    site: 'gornaya-dolina.info',
  },
  {
    avatar: h2,
    title: 'Хостел "Вершина"',
    description:
      '"Вершина" хостел расположенный в центре города Кировск с полностью бесконтактным заселением современный хостел на 12 номеров',
    numbers: 12,
    site: 'vershina-hostel.ru',
    isGreen: true,
  },
  {
    avatar: h3,
    title: 'Мини-отель "Хвоя"',
    description:
      'Отель мидл класса с удачным расположением в центре города Мурманск на 8 номеров с увеличенной площадью',
    numbers: 8,
    isGreen: true,
  },
  {
    avatar: h5,
    avatarStyles: {
      backgroundColor: '#000',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageStyles: { objectFit: 'contain', height: '50px' },
    title: 'Мини-гостиница "Grand Rooms"',
    description: `Почувствовать себя как дома во время долгой поездки — бесценно. Мини-отель «Grandrooms» находится в Мурманске. Этот мини-отель располагается в 6 км от центра города.
Общая кухня оборудована для самостоятельного приготовления пищи. Хотите оставаться на связи? В мини-отеле есть бесплатный Wi-Fi. Если вы путешествуете на машине, припарковаться можно будет на парковке рядом. Если планируете экскурсии, обратите внимание на экскурсионное бюро мини-отеля.
Для простоты передвижения возможна организация трансфера. А ещё в распоряжении гостей прачечная, индивидуальная регистрация заезда и отъезда и гладильные услуги.`,
    // numbers: 8,
    site: 'grandrooms.ru',
    isGreen: false,
  },
  {
    avatar: h4,
    title: 'Аренда квартир в Кировске',
    description:
      'Комфортное проживание для туристов в подготовленных апартаментах',
    numbers: 6,
    cottage: true,
    site: '/arenda-kvartiry-v-kirovske',
    isGreen: true,
    moreButton: true,
  },
]
