import React from 'react'
import './ContactUs.sass'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
import Icon from '../../ui/Icon/Icon'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import ContactUsForm from './ContactUsForm/ContactUsForm'
import ContactUsPopUp from './ContactUsPopUp'
import ctbg from './ctBg.svg'

function ContactUs() {
  return (
    <section
      id="contacts"
      className="ContactUs DefaultSection"
      style={{ backgroundImage: `url(${ctbg})` }}
    >
      <ContactUsPopUp />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Контакты" />
            <div className="ContactUs-Container">
              <div className="ContactUs-Info">
                <div className="ContactUs-Links">
                  <ContactUsInfoTile
                    icon="mobile"
                    data={
                      <>
                        <a href="tel:+7 (921) 158-80-80">+7 (921) 158-80-80</a>
                        {/* <br /> */}
                        {/* <a href="tel:+7 (921) 289-39-39">+7 (921) 289-39-39</a> */}
                      </>
                    }
                    description="Viber, Telegram, Whatsapp"
                  />
                  <ContactUsInfoTile
                    icon="envelope"
                    data="terrahome51@gmail.com"
                  />
                  <ContactUsInfoTile
                    icon="clock"
                    data="с 09:00 до 18:00 Ежедневно"
                  />
                </div>
                <div className="ContactUs-Map">
                  <YMaps>
                    <Map
                      className="Map"
                      defaultState={{
                        center: [68.95923204942511, 33.06397649999999],
                        zoom: 16,
                      }}
                    >
                      <Placemark
                        geometry={[68.95923204942511, 33.06397649999999]}
                        options={{ preset: 'islands#greenIcon' }}
                      />
                    </Map>
                  </YMaps>
                </div>
              </div>
              <ContactUsForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function ContactUsInfoTile({ icon, data, description }) {
  return (
    <div className="ContactUsInfoTile">
      <div className="ContactUsInfoTile-Icon">
        <Icon name={icon} weight="solid" />
      </div>
      <div className="ContactUsInfoTile-Data">
        <p className="Data">{data}</p>
        {description && <p className="Description">{description}</p>}
      </div>
    </div>
  )
}
export default ContactUs
